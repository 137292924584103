// Generated by Framer (1249b65)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./XRTN5es_5-0.js";

const cycleOrder = ["iKcZGT0A0", "nNgGVcFJZ"];

const variantClassNames = {iKcZGT0A0: "framer-v-1h2tpnr", nNgGVcFJZ: "framer-v-7bhhbx"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const valuesByLocaleId = {NTHK784vL: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "iKcZGT0A0", "Variant 2": "nNgGVcFJZ"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, T1lcCvqNP: tap ?? props.T1lcCvqNP, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "iKcZGT0A0"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, T1lcCvqNP, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "iKcZGT0A0", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1yyv02r = activeVariantCallback(async (...args) => {
if (T1lcCvqNP) {
const res = await T1lcCvqNP(...args);
if (res === false) return false;
}
setVariant("nNgGVcFJZ")
})

const onTap1gbauwd = activeVariantCallback(async (...args) => {
if (T1lcCvqNP) {
const res = await T1lcCvqNP(...args);
if (res === false) return false;
}
setVariant("iKcZGT0A0")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-2axWz", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1h2tpnr", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"iKcZGT0A0"} onTap={onTap1yyv02r} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 34, borderBottomRightRadius: 34, borderTopLeftRadius: 34, borderTopRightRadius: 34, ...style}} variants={{nNgGVcFJZ: {backgroundColor: "rgb(0, 0, 0)"}}} {...addPropertyOverrides({nNgGVcFJZ: {"data-framer-name": "Variant 2", onTap: onTap1gbauwd}}, baseVariant, gestureVariant)}><motion.div className={"framer-1ly6mjk"} layoutDependency={layoutDependency} layoutId={"XwBH9BNKO"} style={{backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: "50%", borderBottomRightRadius: "50%", borderTopLeftRadius: "50%", borderTopRightRadius: "50%"}} variants={{nNgGVcFJZ: {backgroundColor: "rgb(255, 255, 255)"}}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-2axWz [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2axWz .framer-2z9xun { display: block; }", ".framer-2axWz .framer-1h2tpnr { cursor: pointer; height: 34px; overflow: hidden; position: relative; width: 60px; will-change: var(--framer-will-change-override, transform); }", ".framer-2axWz .framer-1ly6mjk { bottom: 4px; flex: none; height: 26px; left: 4px; overflow: hidden; position: absolute; width: 26px; will-change: var(--framer-will-change-override, transform); }", ".framer-2axWz.framer-v-7bhhbx .framer-1ly6mjk { left: unset; right: 4px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 34
 * @framerIntrinsicWidth 60
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"nNgGVcFJZ":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"T1lcCvqNP":"tap"}
 * @framerImmutableVariables false
 */
const FramerXRTN5es_5: React.ComponentType<Props> = withCSS(Component, css, "framer-2axWz") as typeof Component;
export default FramerXRTN5es_5;

FramerXRTN5es_5.displayName = "switch c";

FramerXRTN5es_5.defaultProps = {height: 34, width: 60};

addPropertyControls(FramerXRTN5es_5, {variant: {options: ["iKcZGT0A0", "nNgGVcFJZ"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, T1lcCvqNP: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerXRTN5es_5, [])